<template>
    <div class="container_con">
        <el-card v-if="form">
            <div class="con_title"><span></span> 消息详情</div>
            <div class="title">{{ form.title }}</div>
            
            <div class="pp">发布时间{{ form.create_time }}</div>
            <div class="content" v-html="form.content">
            </div>
            <el-descriptions :column="4">
                <el-descriptions-item label="产品名称：">{{ form.extra_data.product_name || '' }}</el-descriptions-item>
                <el-descriptions-item label="产品编号：">{{ form.extra_data.product_no || '' }}</el-descriptions-item>
                <el-descriptions-item label="厂家：">{{ form.extra_data.manufacturer || '' }}</el-descriptions-item>
                <el-descriptions-item label="数量：">{{ form.extra_data.product_num || '' }}</el-descriptions-item>
            </el-descriptions>
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
// 上传组件
export default {
    name: "msgDetail",
    data() {
        return {
            form: ''
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.get_data()
    },
    methods: {

        get_data() {
            this.$httpGet("/backend/Message/read", {
                id: this.id
            }).then((res) => {
                if (res.status == 200) {
                    let _data = res.data;
                    this.form = _data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 18px;
    padding-bottom: 10px;
}

.pp {
    color: #999;
    font-size: 12px;
}

.content {
    font-size: 14px;
    padding: 20px 0;
    color: #333;
    line-height: 24px;
}
</style>
